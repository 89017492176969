$white: #fff;
$light: #f2f2f2;
$dark: #444;
$grey: #d8d8d8;

body {
  height: 100vh;
  width: 100vw;
  // background-color: $light;
  color: $dark;
  font-family: "Manrope", sans-serif;
}

h1 {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  color: $dark;
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  width: 100vw;
  max-width: 1200px;

  margin: 0 auto;
  padding: 7vw;

  box-sizing: border-box;

  @media screen and (min-width: 1300px) {
    padding: 0;
  }
} // end .container

.card {
  width: 100%;
  max-width: 400px;

  background-color: $white;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  margin-top: 40px;

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 150px;
    padding: 30px;
    border-bottom: 1px solid rgba($grey, 0.5);

    text-align: center;
  } // end &-logo

  &-body {
    padding: 20px;
  } // end &-body

  &-post {
    margin-top: 20px;
    opacity: 0.2;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
  } // end &-post
} // end .card

.success {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 20px 100px;

  &-icon {
    color: #9fda8c;
  }

  &-title {
    margin-top: 30px;
    display: block;
    font-size: 26px;
    color: $dark;
    letter-spacing: 0;
  } // end &-title

  &-subtitle {
    display: block;
    margin-top: 10px;
    opacity: 0.5;
    font-size: 16px;
    text-align: center;
    color: $dark;
    letter-spacing: 0;
  }
} // end .success

.newpayment {
  flex-direction: column;

  @media screen and (min-width: 700px) {
    flex-direction: row;
  }

  &-hero {
    flex: 1;
    width: 100%;
    max-width: 400px;
    margin-bottom: 10vw;

    @media screen and (min-width: 700px) {
      margin-bottom: 0;
      margin-right: 10vw;
    }

    p {
      margin-top: 26px;
      font-size: 20px;
      line-height: 32px;
    } // end p
  } // end &-hero

  &-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    width: 100%;
  } // end &-card
} // end .newpayment

.input {
  background: #e7e7e7;
  color: $dark;
  border-radius: 5px;
  font-size: 15px;
  letter-spacing: 0;
  padding: 12px 14px;
  margin-bottom: 10px;
  font-family: sans-serif;

  &::placeholder {
    color: darkgray;
  }

  &-picker {
    padding: 5px 2px 6px;
    width: 100%;

    .rs-picker-toggle {
      background-color: transparent !important;
    }
  } // end &-picker

  &-stripeParent {
    height: 48px;
    margin-bottom: 10px;
    background: #e7e7e7;
    border-radius: 5px;
  }

  &-stripe {
    padding: 16px 14px 15px;
  } // end &-stripe

  &-amount {
    width: 100%;
    border: none;
    font-size: 50px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 20px;

    &:focus {
      outline: none;
    }
  }
} // end .input
